import React, { useState, useEffect } from 'react';
import { Button, Select, Space } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'moment-timezone';

const { Option } = Select;

interface DateNavigationHeaderProps {
  onDateChange: (date: string) => void;
  disabled?: boolean;
  timeZone?: string;
}

const DateNavigationHeader: React.FC<DateNavigationHeaderProps> = ({ onDateChange, disabled = false, timeZone = 'UTC' }) => {
  const [currentDate, setCurrentDate] = useState(moment().tz(timeZone));

  // Effect to update currentDate when the timeZone prop changes
  useEffect(() => {
    setCurrentDate(moment().tz(timeZone));
  }, [timeZone]);

  // Effect to call onDateChange whenever currentDate changes
  useEffect(() => {
    onDateChange(currentDate.format('YYYY-MM-DD'));
  }, [currentDate, onDateChange]);

  const getDateOptions = () => {
    return [
      moment().tz(timeZone).subtract(2, 'days'),
      moment().tz(timeZone).subtract(1, 'days'),
      moment().tz(timeZone)
    ].map(date => ({
      label: date.format('MMM - D - YYYY'),
      value: date.format('YYYY-MM-DD')
    }));
  };

  const handleLeftClick = () => {
    const newDate = currentDate.clone().subtract(1, 'days');
    if (newDate.isAfter(moment().tz(timeZone).subtract(3, 'days'))) {
      setCurrentDate(newDate);
    }
  };

  const handleRightClick = () => {
    const newDate = currentDate.clone().add(1, 'days');
    if (newDate.isBefore(moment().tz(timeZone).add(1, 'days'))) {
      setCurrentDate(newDate);
    }
  };

  const handleDateChange = (value: string) => {
    setCurrentDate(moment(value).tz(timeZone));
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 20px', backgroundColor: '#f0f2f5' }}>
      <Button 
        icon={<LeftOutlined />} 
        onClick={handleLeftClick} 
        style={{ marginRight: '20px' }}
        disabled={disabled || currentDate.isSame(moment().tz(timeZone).subtract(2, 'days'), 'days')}
      />
      
      <Space direction="vertical">
        <Select 
          value={currentDate.format('YYYY-MM-DD')}
          onChange={handleDateChange}
          style={{ width: 160 }}
          bordered={false}
          dropdownStyle={{ border: '1px solid #d9d9d9' }}
          disabled={disabled}
        >
          {getDateOptions().map(option => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Space>
      
      <Button 
        icon={<RightOutlined />} 
        onClick={handleRightClick} 
        style={{ marginLeft: '20px' }}
        disabled={disabled || currentDate.isSame(moment().tz(timeZone), 'days')}
      />
    </div>
  );
};

export default DateNavigationHeader;
