import React, { useState, useEffect } from 'react';
import { Button, Drawer, Input, Avatar, List, message, Select, Spin } from 'antd';
import { RightOutlined, UserAddOutlined } from '@ant-design/icons';
import { fetchEmployeesList, fetchTimeZoneList, fetchDepartmentList, fetchManageUser } from '../../fetch/fetch';
import { useNavigate } from 'react-router-dom';

interface Leader {
  leader_id: string;
  leader_name: string;
}

const EmployeesList: React.FC<{ jwtToken: string }> = ({ jwtToken }) => {
  const [visible, setVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newMember, setNewMember] = useState({
    email: '',
    name: '',
    timezone: '',
    department: '',
    leadership: '',
    uid: ""
  });
  const [employees, setEmployees] = useState<any[]>([]);
  const [TimeZoneList, setTimeZoneList] = useState<any[]>([]);
  const [DepartmentList, setDepartmentList] = useState<any[]>([]);
  const [leadersList, setLeadersList] = useState<Leader[]>([]);
  const navigate = useNavigate();

  // Loading state for the list of employees
  const [loading, setLoading] = useState(true);

  // Loading state for form submission (Add/Edit member)
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    getList();
    // Fetch timezones
    fetchTimeZoneList(jwtToken).then((res) => {
      if (res.status === "1") {
        setTimeZoneList(res.response);
      } else if (res.status === "-1") {
        navigate('/logout');
      } else {
        message.error(res.message);
      }
    }).catch((error: any) => {
      console.log(error);
    });

    // Fetch departments
    fetchDepartmentList(jwtToken).then((res) => {
      if (res.status === "1") {
        setDepartmentList(res.response);
      } else if (res.status === "-1") {
        navigate('/logout');
      } else {
        message.error(res.message);
      }
    }).catch((error: any) => {
      console.log(error);
    });
  }, [jwtToken]);

  const getList = () => {
    setLoading(true);
    // Fetch employees list
    fetchEmployeesList(jwtToken).then((res) => {
      setLoading(false);
      if (res.status === "1") {
        setEmployees(res.response);
      } else if (res.status === "-1") {
        navigate('/logout');
      } else {
        message.error(res.message);
      }
    }).catch((error: any) => {
      console.log(error);
      setLoading(false);
    });
  }

  const handleAddMember = async () => {
    if (!newMember.email || !newMember.name || !newMember.timezone || !newMember.department || !newMember.leadership) {
      message.error('Please fill in all the required fields!');
      return;
    }

    setSubmitLoading(true); // Set loading to true when the API call starts

    try {
      const params = {
        email: newMember.email,
        name: newMember.name,
        time_zone: newMember.timezone,
        leader_id: newMember.leadership,
        department_id: newMember.department,
        ...(isEditing && { uid: newMember.uid }), // Only include uid if editing
      };

      const response = await fetchManageUser(jwtToken, params);

      if (response.status === '1') {
        message.success(isEditing ? 'Member updated successfully!' : 'Member added successfully!');
        setNewMember({ email: '', name: '', timezone: '', department: '', leadership: '', uid: '' });
        setVisible(false);
        setIsEditing(false);
        getList();
      } else if (response.status === "-1") {
        navigate('/logout');
      } else {
        message.error(response.message || 'An error occurred');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      message.error('Failed to add or update member');
    } finally {
      setSubmitLoading(false); // Reset loading state when the API call finishes
    }
  };

  const handleEditMember = (employee: any) => {
    const selectedDepartment = DepartmentList.find(
      (department) => department.department_id === employee.department_id
    );

    if (selectedDepartment) {
      setLeadersList(selectedDepartment.leaders);
    }
    setNewMember({
      email: employee.email,
      name: employee.name,
      timezone: employee.time_zone,
      department: employee.department_id,
      leadership: employee.leader_id,
      uid: employee.uid
    });
    setIsEditing(true);
    setVisible(true);
  };

  const handleDepartmentChange = (value: string) => {
    const selectedDepartment = DepartmentList.find(department => department.department_id === value);
    if (selectedDepartment) {
      setLeadersList(selectedDepartment.leaders);
      setNewMember({ ...newMember, department: value, leadership: '' });
    }
  };

  return (
    <div style={{ padding: '0 15px' }}>
      {loading ? (
        <Spin tip="Loading..." style={{ display: 'block', margin: '100px auto' }} />
      ) : (
        <>
          <List
            itemLayout="horizontal"
            dataSource={employees}
            renderItem={(employee) => (
              <List.Item
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                onClick={() => handleEditMember(employee)}
              >
                <List.Item.Meta
                  avatar={
                    <Avatar
                      style={{
                        backgroundColor: '#f0f0f0',
                        color: '#000',
                        fontWeight: 'bold',
                      }}
                    >
                      {employee.name.charAt(0).toUpperCase()}
                    </Avatar>
                  }
                  title={<span>{employee.name}</span>}
                  description={<div>{employee.email}</div>}
                />
                <RightOutlined style={{ marginLeft: 'auto' }} />
              </List.Item>
            )}
          />
          <div style={{height:40}}></div>

          <Button
            type="primary"
            style={{
              position: 'fixed',
              bottom: '20px',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '80vw',
              maxWidth: '600px',
            }}
            icon={<UserAddOutlined />}
            onClick={() => {setVisible(true);setNewMember({ email: '', name: '', timezone: '(GMT-06:00) America/Chicago', department: '', leadership: '', uid: '' });}}
          >
            Add Members
          </Button>

          <Drawer
            title={isEditing ? 'Edit Member' : 'Add New Member'}
            width={400}
            placement="right"
            closable={true}
            onClose={() => {
              if (!submitLoading) {  // Allow closing only when not loading
                setVisible(false);
                setIsEditing(false);
                setNewMember({ email: '', name: '', timezone: '', department: '', leadership: '', uid: '' });
              }
            }}
            visible={visible}
            height="auto"
          >
            <div style={{ marginBottom: '16px' }}>
              <label style={{ display: 'block', marginBottom: '8px' }}>
                Email <span style={{ color: 'red' }}>*</span>
              </label>
              <Input
                placeholder="Email"
                value={newMember.email}
                onChange={(e) =>
                  setNewMember({ ...newMember, email: e.target.value })
                }
                style={{ marginBottom: '8px' }}
                disabled={isEditing}
              />
            </div>

            <div style={{ marginBottom: '16px' }}>
              <label style={{ display: 'block', marginBottom: '8px' }}>
                Name <span style={{ color: 'red' }}>*</span>
              </label>
              <Input
                placeholder="Name"
                value={newMember.name}
                onChange={(e) =>
                  setNewMember({ ...newMember, name: e.target.value })
                }
                style={{ marginBottom: '8px' }}
              />
            </div>

            <div style={{ marginBottom: '16px' }}>
              <label style={{ display: 'block', marginBottom: '8px' }}>
                Time Zone <span style={{ color: 'red' }}>*</span>
              </label>

              <Select
                placeholder="Select Time Zone"
                value={newMember.timezone}
                onChange={(value) => { setNewMember({ ...newMember, timezone: value }); }}
                style={{ width: '100%', marginBottom: '8px' }}
                showSearch
                filterOption={(input, option) =>
                  String(option?.children).toLowerCase().includes(input.toLowerCase())
                }
              >
                {TimeZoneList.map((timezone, index) => (
                  <Select.Option key={index} value={timezone.name}>
                    {timezone.name}
                  </Select.Option>
                ))}
              </Select>
            </div>

            <div style={{ marginBottom: '16px' }}>
              <label style={{ display: 'block', marginBottom: '8px' }}>
                Department <span style={{ color: 'red' }}>*</span>
              </label>
              <Select
                placeholder="Select Department"
                value={newMember.department}
                onChange={handleDepartmentChange}
                style={{ width: '100%', marginBottom: '8px' }}
              >
                {DepartmentList.map((department, index) => (
                  <Select.Option key={index} value={department.department_id}>
                    {department.name}
                  </Select.Option>
                ))}
              </Select>
            </div>

            <div style={{ marginBottom: '16px' }}>
              <label style={{ display: 'block', marginBottom: '8px' }}>
                Supervisor <span style={{ color: 'red' }}>*</span>
              </label>
              <Select
                placeholder="Select supervisor"
                value={newMember.leadership}
                onChange={(value) => setNewMember({ ...newMember, leadership: value })}
                style={{ width: '100%', marginBottom: '8px' }}
              >
                {leadersList.map((leader, index) => (
                  <Select.Option key={index} value={leader.leader_id}>
                    {leader.leader_name}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <Button
              type="primary"
              onClick={handleAddMember}
              style={{ width: '100%' }}
              loading={submitLoading}  // Apply the loading state
              icon={submitLoading ? <Spin size="small" /> : null}  // Optional: customize loading icon
            >
              {isEditing ? 'Update Member' : 'Add Member'}
            </Button>
          </Drawer>
        </>
      )}
    </div>
  );
};

export default EmployeesList;
