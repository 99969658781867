import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import TaskList from '../employee/TaskList';
import EmployeesList from './EmployeesList';
import { useAuth,returnGrops } from '../../Hooks/UserHooks';

const { TabPane } = Tabs;

const Manage: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>('tasks');
  const { userProfile } = useAuth();
  

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };
  

  let auth = ''
  let jwtToken = ''
  if(userProfile){
      auth = returnGrops(userProfile)
      jwtToken = userProfile.signInUserSession.accessToken.jwtToken
  }
  return (
    <div className='manage-page'>
      <Tabs
        activeKey={activeTab}
        onChange={handleTabChange}
        style={{padding:"10px 0 0 0"}}
        tabBarStyle={{
          display: 'flex',
          width: '100%',
        }}
      >
        <TabPane
          tab={<div style={{ textAlign: 'center', width: '50%' }}>Task List</div>}
          key="tasks"
        >
          <TaskList auth={auth} jwtToken={jwtToken} isManage={true}/>
        </TabPane>
        <TabPane
          tab={<div style={{ textAlign: 'center', width: '50%' }}>Employees List</div>}
          key="employees"
        >
          <EmployeesList jwtToken={jwtToken} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Manage;
