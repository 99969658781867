import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { message } from 'antd';
import { returnGrops } from "../Hooks/UserHooks";

const HandleLogin: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    // Flag to avoid repeated requests
    let hasFetched = false;

    const handleHashLogin = async () => {
      if (hasFetched) return;  // Prevent repeated calls
      hasFetched = true;

      const hash = new URLSearchParams(location.search).get('hash');
      if (hash) {
        const decodeHash = JSON.parse(atob(hash));
        const { email, code } = decodeHash || {};

        if (email && code) {
          try {
            const cognitoUser = await Auth.signIn(email);
            if (cognitoUser) {
              submitCode(cognitoUser, code);
            }
          } catch (error: any) {
            console.error(error.message);
            setError(true);  // Set error flag if authentication fails
            setLoading(false); // Stop loading
          }
        } else {
          console.error('This magic link has been used, please request a new magic link.');
          setError(true);  // Set error flag for invalid link
          setLoading(false); // Stop loading
        }
      }
    };

    const submitCode = async (user: any, code: string) => {
      try {
        await Auth.sendCustomChallengeAnswer(user, code);
        console.log(user, 'user');
        if (user) {
          let auth = returnGrops(user);
          if (auth === 'dkj-admin') {
            navigate('/manage');
          } else {
            navigate('/employee');
          }
        }
      } catch (error: any) {
        setError(true); // Set error flag for failed code submission
        setLoading(false); // Stop loading
        localStorage.removeItem('loginRedirect');
        // message.error('Login failed: This link is invalid. Please request a new one');
        
        // Redirect to login page after 3 seconds
        setTimeout(() => {
          navigate('/login');
        }, 2000);
      }
    };

    handleHashLogin();
  }, [location.search, navigate]);

  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '50px' }}>
        <h1>Logging in...</h1>
        <p>Please wait while we verify your login details.</p>
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ textAlign: 'center', marginTop: '50px' }}>
        <h1>Login Failed</h1>
        <p>There was an issue with the login process. You will be redirected shortly.</p>
      </div>
    );
  }

  return null;  // Return nothing while loading or on success (handled in the navigate callback)
};

export default HandleLogin;
