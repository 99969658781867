import React, { useEffect } from "react";
import { Amplify, Auth } from "aws-amplify";
import amplifyconfig from "../amplifyconfiguration.json";
Amplify.configure(amplifyconfig);

function App() {
  const [email, setEmail] = React.useState("xu.sun@rd.eiqhome.com");
  const [password, setPassword] = React.useState("BPadmin13.");
  const [hash, setHash] = React.useState("");

  useEffect(() => {
    const checkUser = async () => {
      const user = await Auth.currentUserInfo();
      console.log(user);
    };

    checkUser();
  }, []);

  const signIn = async () => {
    try {
      const user = await Auth.signIn(email, password);
      console.log("Success:", user);
    } catch (error) {
      console.error("Failer:", error);
    }
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
      console.log("Success");
    } catch (error) {
      console.error("Failer:", error);
    }
  };

  const sendCode = async () => {
    try {
      const response = await fetch(
        "https://z7addc12sl.execute-api.us-east-2.amazonaws.com/v1/dkj/User/OTPSendCode",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Email: email,
          }),
        }
      );
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const challengeAnswer = async () => {
    const decodeHash = JSON.parse(atob(hash));
    const { email, code } = decodeHash || {};
    console.log(email, code);
    if (email && code) {
      try {
        const cognitoUser = await Auth.signIn(email);
        if (cognitoUser) {
          console.log("cognitoUser", cognitoUser);
          submitCode(cognitoUser, code);
        }
      } catch (error: any) {
        // if (error?.code === "UserLambdaValidationException") {
        //   console.error("User does not exist");
        // } else {
        console.error(error.message);
        // }
      }
    } else {
      console.error(
        "This magic link has been used, please request a new magic link."
      );
      return;
    }
  };

  const submitCode = async (user: any, code: any) => {
    console.log("submitCode", user, code);
    try {
      await Auth.sendCustomChallengeAnswer(user, code);
    } catch (error: any) {
      localStorage.removeItem("loginRedirect");
      // if (error?.code === "UserLambdaValidationException") {
      //   console.error(
      //     "This magic link has been used, please request a new magic link."
      //   );
      // } else {
      console.error(error?.message);
      // }
    }
  };

  return (
    <div className="App">
      <button type="button" onClick={signIn}>
        Sign in
      </button>
      <button type="button" onClick={signOut}>
        Sign out
      </button>

      <hr />

      <input value={email} onChange={(evt) => setEmail(evt.target.value)} />
      <button type="button" onClick={sendCode}>
        Send Code
      </button>

      <hr />

      <input value={hash} onChange={(evt) => setHash(evt.target.value)} />
      <button type="button" onClick={challengeAnswer}>
        Magic Link Login
      </button>
    </div>
  );
}

export default App;
