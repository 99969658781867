import React, { useState } from 'react';
import { Input, Button, Typography, Space, Image, message } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import eiq_logo from '../public/images/eiq_logo.png';

const { Title } = Typography;

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleLogin = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        'https://z7addc12sl.execute-api.us-east-2.amazonaws.com/v1/dkj/User/OTPSendCode',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            Email: email,
          }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        message.success('Email sent successfully. Please check your inbox to log in.');
      } else {
        message.error('Failed to send email. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      message.error('Network error. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f0f2f5',
        padding: '20px',
      }}
    >
      <Space
        direction="vertical"
        size="large"
        style={{
          textAlign: 'center',
          width: '100%',
          maxWidth: '480px',
          backgroundColor: 'white',
          padding: '30px',
          borderRadius: '10px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Image src={eiq_logo} alt="Logo" width={200} preview={false} />
        <Title level={2} style={{ marginBottom: '20px' }}>
          Hi, Welcome to
        </Title>

        <div style={{ width: '100%' }}>
          <Input
            placeholder="Please enter your Email or Phone"
            value={email}
            onChange={handleInputChange}
            style={{
              width: '100%',
              padding: '12px',
              fontSize: '16px',
            }}
            prefix={<MailOutlined />}
          />
        </div>

        <Button
          type="primary"
          onClick={handleLogin}
          loading={loading}
          style={{
            width: '100%',
            padding: '12px',
            fontSize: '16px',
            borderRadius: '5px',
          }}
        >
          Login Now
        </Button>
      </Space>
    </div>
  );
};

export default LoginPage;
