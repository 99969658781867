import React, { useState, useEffect } from 'react';
import { InputNumber, Button, List, Typography, Divider, Input, Drawer, Select, message, Spin, Modal, Tag } from 'antd';
import { DeleteOutlined, ClockCircleOutlined, AppstoreAddOutlined, EditOutlined, SwapOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons'; // For download icon
import { Calendar } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { fetchAdminTaskList, fetchEmployeesList, fetchAdminAddTask, fetchEmployeeTaskList, fetchUnclaimedTaskList, fetchTakeTask, fetchEditTask, fetchAdminEditTask } from '../../fetch/fetch';

const { Text } = Typography;
const { Option } = Select;

const TaskList: React.FC<{ isManage: boolean, auth: string, jwtToken: string, headerSelectedDate?: string, timeZone?: string }> = ({ isManage, auth, jwtToken, headerSelectedDate }) => {
  const [tasks, setTasks] = useState<any>([]);

  const [visible, setVisible] = useState(false);
  const [newTask, setNewTask] = useState({ title: '', hours: 0, role: '' });
  const [selectedDateRange, setSelectedDateRange] = useState<string>('');  // Updated name
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [employees, setEmployees] = useState<any[]>([]);
  const navigate = useNavigate();

  const [addTaskDrawerVisible, setAddTaskDrawerVisible] = useState(false); // For Add Task Drawer
  const [addTaskForm, setAddTaskForm] = useState({ title: '', comments: '', member: [], task_id: '' }); // New task form state
  const [unclaimedTaskList, setUnclaimedTaskList] = useState<any[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    if (jwtToken) {
      if (isManage) {
        adminTask()
        fetchEmployeesList(jwtToken).then((res) => {
          if (res.status === "1") {
            setEmployees(res.response);
          } else if (res.status === "-1") {
            navigate('/logout');
          } else {
            setEmployees([]);
            message.error(res.message);
          }
        }).catch((error: any) => {
          setEmployees([]);
          console.log(error);
        });
      }
    }
  }, [jwtToken]);

  useEffect(() => {
    if (!isManage && headerSelectedDate) {
      employeeTaskHandle()
      UnclaimedTaskListHandle()
    }
  }, [headerSelectedDate])

  useEffect(() => {
    const endDate = moment();
    const startDate = moment().subtract(2, 'weeks');
    const formattedRange = `${startDate.format('MM-DD-YYYY')} - ${endDate.format('MM-DD-YYYY')}`;
    setSelectedDateRange(formattedRange);
  }, []);

  const handleCalendarChange = (value: any) => {
    if (value && value.length === 2) {
      const startDate = moment(value[0]);
      const endDate = moment(value[1]);

      const formattedRange = `${startDate.format('MM-DD-YYYY')} - ${endDate.format('MM-DD-YYYY')}`;
      setSelectedDateRange(formattedRange);
    }
  };
  const UnclaimedTaskListHandle = () => {
    let params = {
      search_date: headerSelectedDate
    }
    fetchUnclaimedTaskList(jwtToken, params).then((res) => {
      if (res.status === "1") {
        setUnclaimedTaskList(res.response);
      } else if (res.status === "-1") {
        navigate('/logout');
      } else {
        message.error(res.message);
      }
    }).catch((error: any) => {
      console.log(error);
    });

  }


  const adminTask = () => {
    setLoading(true);
    fetchAdminTaskList(jwtToken).then((res) => {
      setLoading(false);
      if (res.status === "1") {
        const updatedTasks = res.response.map((task: any) => {
          const formattedDate = moment(task.created_ts).format('MM/DD HH:mm:ss');
          return {
            ...task,
            created_ts: formattedDate,
          };
        });

        setTasks(updatedTasks);
      } else if (res.status === "-1") {
        navigate('/logout');
      } else {
        setTasks([]);
        message.error(res.message);
      }
    }).catch((error: any) => {
      setTasks([]);
      setLoading(false);
      console.log(error);
    });
  }

  const employeeTaskHandle = () => {
    setLoading(true);
    let params = {
      search_date: headerSelectedDate
    }
    fetchEmployeeTaskList(jwtToken, params).then((res) => {
      setLoading(false);
      if (res.status === "1") {
        const updatedTasks = res.response.map((task: any) => {
          const formattedDate = moment(task.created_ts).format('MM/DD HH:mm:ss');
          return {
            ...task,
            created_ts: formattedDate,
          };
        });

        setTasks(updatedTasks);
      } else if (res.status === "-1") {
        navigate('/logout');
      } else {
        setTasks([]);
        message.error(res.message);
      }
    }).catch((error: any) => {
      setTasks([]);
      setLoading(false);
      console.log(error);
    });
  }
  const handleDownload = async () => {
    const [start, end] = selectedDateRange.split(' - ');

    let param = {
      search_start_date: moment(start, 'MM-DD-YYYY').format('YYYY-MM-DD'),
      search_end_date: moment(end, 'MM-DD-YYYY').format('YYYY-MM-DD'),
    };

    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${jwtToken}`);
      myHeaders.append("Content-Type", "application/json");
      const API_URL = 'https://z7addc12sl.execute-api.us-east-2.amazonaws.com/v1/dkj'
      const url = `${API_URL}/Admin/download`;

      // Sending a POST request with the parameters
      const response = await fetch(url, {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(param),  // Sending parameters as a JSON string
        redirect: "follow",
      });

      // Check if the response is successful
      if (!response.ok) {
        throw new Error("File download failed");
      }

      // Get the Blob data (file)
      const blob = await response.blob();

      // Create a temporary download link
      const downloadUrl = URL.createObjectURL(blob);

      // Create an <a> element and simulate a click to trigger the download
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = `TaskList(${selectedDateRange})`; // Custom file name (you can dynamically set it as needed)
      link.click();  // Trigger the download

      // Clean up the URL object after download
      URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      // Handle errors
      console.error('Error during file download:', error);
    }
  };

  const handleConfirmDateRange = () => {
    setIsDrawerVisible(false);
  };

  const handleDeleteTask = (taskId: number) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this task?',
      centered: true, // This centers the modal
      onOk: () => {
        setLoading(true);
        const taskPayload = {
          task_log_id: taskId,
          status: 'Deleted',
        };
        fetchEditTask(jwtToken, taskPayload).then((res) => {
          setLoading(false);
          if (res.status === '1') {
            message.success(res.message);
            employeeTaskHandle();
          } else {
            message.error(res.message);
          }
        }).catch((error: any) => {
          setLoading(false);
          console.log(error);
        });
      },
      onCancel() {
        // Do nothing if the user cancels
      },
    });
  };


  const handleAddTask = () => {
    if (!newTask.title || newTask.hours <= 0 || !newTask.role) {
      alert('All fields are required!');
      return;
    }
    setSubmitLoading(true);
    const taskPayload = {
      task_id: newTask.title,
      estimated_hrs: newTask.hours,
      take_date: headerSelectedDate,
      role: newTask.role,
    };
    fetchTakeTask(jwtToken, taskPayload).then((res) => {
      setSubmitLoading(false);
      if (res.status === "1") {
        message.success(res.message);
        setNewTask({ title: '', hours: 0, role: '' });
        setVisible(false);
        employeeTaskHandle()
      } else if (res.status === "-1") {
        navigate('/logout');
      } else {
        message.error(res.message);
      }
    }).catch((error: any) => {
      setSubmitLoading(false);
      console.log(error);
    });
  };

  const handleAddNewTask = () => {
    if (!addTaskForm.title || !addTaskForm.comments) {
      alert('All fields are required!');
      return;
    }

    if (addTaskForm.member.length === 0) {
      message.error('Please select at least one member.');
      return;
    }
    setSubmitLoading(true);
    const taskPayload = {
      title: addTaskForm.title,
      comment: addTaskForm.comments,
      uids: addTaskForm.member,
      ...(addTaskForm.task_id && { task_id: addTaskForm.task_id })
    };
    if (isEditing) {

      fetchAdminEditTask(jwtToken, taskPayload).then((res) => {
        setSubmitLoading(false);
        if (res.status === "1") {
          message.success(res.message);
          setAddTaskForm({ title: '', comments: '', member: [], task_id: "" });
          setAddTaskDrawerVisible(false);
          adminTask()
        } else if (res.status === "-1") {
          navigate('/logout');
        } else {
          message.error(res.message);
        }
      }).catch((error: any) => {
        setSubmitLoading(false);
        console.log(error);
      });
    } else {
      fetchAdminAddTask(jwtToken, taskPayload).then((res) => {
        if (res.status === "1") {
          setSubmitLoading(false);
          message.success(res.message);
          setAddTaskForm({ title: '', comments: '', member: [], task_id: "" });
          setAddTaskDrawerVisible(false);
          adminTask()
        } else if (res.status === "-1") {
          navigate('/logout');
        } else {
          message.error(res.message);
        }
      }).catch((error: any) => {
        setSubmitLoading(false);
        console.log(error);
      });

    }
  };
  const EditTaskHandle = (task: any) => {
    const selectedTask = tasks.find(
      (item: any) => item.task_id === task.task_id
    );

    if (selectedTask) {
      setAddTaskForm({ title: selectedTask.title, comments: selectedTask.comments, member: selectedTask.assigned_to.map((item: any) => item.assigned_to), task_id: selectedTask.task_id });
    }
    setAddTaskDrawerVisible(true);
    setIsEditing(true);
  }

  return (
    <div style={{ padding: '20px', maxWidth: '400px', margin: '0 auto' }}>
      {/* {isManage && <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
        <Select
          defaultValue="all"
          style={{ width: 60 }}
          dropdownStyle={{ minWidth: 120 }}
        >
          <Option value="all">All</Option>
          <Option value="completed">Completed</Option>
          <Option value="in-progress">In Progress</Option>
        </Select>
        <Input placeholder="Search tasks" style={{ flex: 1 }} />
        <Button type="primary">Search</Button>
      </div>} */}
      {isManage && <div
        style={{
          display: 'flex',
          gap: '16px',
          alignItems: 'center',
          width: '90vw',
          maxWidth: '600px',
          marginBottom: '20px'
        }}
      >
        <Input
          style={{ width: 250 }}
          value={selectedDateRange}
          readOnly
          placeholder="Start date - End date"
          onClick={() => setIsDrawerVisible(true)} // Open the drawer when clicked
        />

        {/* Download Data Button */}
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          onClick={handleDownload}
        >
          Download Data
        </Button>
      </div>}

      {!isManage && (
        <div
          style={{
            textAlign: 'center'
          }}>

          <Button
            type="primary"
            style={{
              width: '90vw',
              maxWidth: '600px',
              marginBottom: '20px'
            }}
            icon={<AppstoreAddOutlined />}
            onClick={() => setVisible(true)}
          >
            Add Hours
          </Button>
        </div>
      )}
      {loading ? (
        <Spin tip="Loading..." style={{ display: 'block', margin: '100px auto' }} />
      ) :
        <List
          dataSource={tasks}
          renderItem={(task: any) => (
            <div style={{ marginBottom: '10px', padding: '15px', border: '1px solid #ddd', borderRadius: '8px' }}>
              <div style={{ display: 'flex', alignItems: 'start', width: '100%' }}>
                <ClockCircleOutlined style={{ color: 'orange', fontSize: '24px', marginRight: '10px' }} />
                <div style={{ flex: 1 }}>
                  <Text strong>{task.title.charAt(0).toUpperCase() + task.title.slice(1)}</Text>

                  {/* Conditional rendering for manage vs non-manage */}
                  {isManage ? (
                    <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <span
                        title={task.comments}
                        style={{
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          WebkitLineClamp: 2,
                          textOverflow: 'ellipsis',
                        }}
                      >
                        Comments: {task.comments}
                      </span>
                    </div>
                  ) : (
                    <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center',justifyContent:'space-between' }}>
                      <div>
                        <Text type="secondary">Spent: </Text>
                        <Text type="secondary" style={{ marginLeft: 8, fontWeight: '700', color: 'black' }}>
                          {task.estimated_hrs}hrs
                        </Text>
                      </div>
                      <Tag style={{  marginRight: 0 }} color="blue">{task.role}</Tag>
                    </div>
                  )}

                  <Divider style={{ margin: '10px 0' }} />

                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                      <Text type="secondary"> {isManage ? 'Added Date' : 'Add task time'}: </Text>
                      <Text>{task.created_ts}</Text>
                    </div>

                    {/* Manage Actions */}
                    {isManage ? (
                      <Button
                        type="link"
                        icon={<EditOutlined />}
                        onClick={() => EditTaskHandle(task)}
                        style={{ padding: 0, marginLeft: 8 }}
                      >
                        Edit
                      </Button>
                    ) : (
                      <Button
                        type="default"
                        size="small"
                        style={{ color: 'gray', marginLeft: 8, borderColor: 'gray', backgroundColor: 'transparent' }}
                        onClick={() => handleDeleteTask(task.task_log_id)}
                      >
                        Delete
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        />}
      {<div style={{ height: 50 }}></div>}

      {isManage && (<>
        <Button
          type="primary"
          shape="circle"
          icon={<SwapOutlined />}
          style={{
            position: 'fixed',
            bottom: '80px',
            right: '20px',
            fontSize: '24px',
          }}
          onClick={() => {
            navigate('/employee')
          }}
        />
        <Button
          type="primary"
          style={{
            position: 'fixed',
            bottom: '20px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '80vw',
            maxWidth: '600px',
          }}
          icon={<AppstoreAddOutlined />}
          onClick={() => { setAddTaskDrawerVisible(true); setIsEditing(false) }}
        >
          Add Task
        </Button>

        {/* <Button
          type="primary"
          shape="circle"
          icon={<AppstoreAddOutlined />}
          style={{
            position: 'fixed',
            bottom: '80px',
            right: '20px',
            fontSize: '24px',
            zIndex: 1000
          }}
          onClick={() => setAddTaskDrawerVisible(true)}
        /> */}
      </>)}
      {auth === "dkj-admin" && !isManage && <Button
        type="primary"
        shape="circle"
        icon={<SwapOutlined />}
        style={{
          position: 'fixed',
          bottom: '40px',
          right: '20px',
          fontSize: '24px',
        }}
        onClick={() => {
          navigate('/manage')
        }}
      />}

      {/* Drawer for adding new task */}
      <Drawer
        title="Add Hours"
        width={300}
        placement="bottom"
        closable={true}
        onClose={() => { if (!submitLoading) { setVisible(false) } }}
        visible={visible}
        height="auto"
      >
        <div style={{ marginBottom: '16px' }}>
          <label style={{ display: 'block', marginBottom: '8px' }}>
            Task Name <span style={{ color: 'red' }}>*</span>
          </label>
          <Select
            placeholder="Select Task"
            value={newTask.title}
            onChange={(e) => setNewTask({ ...newTask, title: e })}
            style={{ width: '100%' }}
          >
            {unclaimedTaskList.map((task, index) => (
              <Option key={index} value={task.task_id}>
                {task.title}
              </Option>
            ))}
          </Select>

        </div>

        <div style={{ marginBottom: '16px' }}>
          <label style={{ display: 'block', marginBottom: '8px' }}>
            Role <span style={{ color: 'red' }}>*</span>
          </label>
          <Select
            placeholder="Select Role Type"
            value={newTask.role}
            onChange={(value) => setNewTask({ ...newTask, role: value })}
            style={{ width: '100%' }}
          >
            <Option value="PM">PM</Option>
            <Option value="BA">BA</Option>
            <Option value="DEV">DEV</Option>
            <Option value="QA">QA</Option>
            <Option value="Meeting">Meeting</Option>
            <Option value="Others">Others</Option>
          </Select>
        </div>
        <div style={{ marginBottom: '16px' }}>
          <label style={{ display: 'block', marginBottom: '8px' }}>
            Task Hours <span style={{ color: 'red' }}>*</span>
          </label>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <InputNumber
              placeholder="Task Hours"
              min={0}
              value={newTask.hours}
              onChange={(value) => setNewTask({ ...newTask, hours: value || 0 })}
              style={{ flex: 1, maxWidth: 'calc(100% - 40px)' }}  // Adjust the width
            />
            <Text type="secondary" style={{ marginLeft: 8 }}>hrs</Text>
          </div>
        </div>

        <Button type="primary" onClick={handleAddTask} style={{ width: '100%' }}
          loading={submitLoading}  // Apply the loading state
          icon={submitLoading ? <Spin size="small" /> : null}>
          Add
        </Button>
      </Drawer>


      <Drawer
        title={isEditing ? 'Edit Task' : 'Add Task'}
        width={300}
        placement="bottom"
        closable={true}
        onClose={() => { if (!submitLoading) { setAddTaskDrawerVisible(false); setAddTaskForm({ title: '', comments: '', member: [], task_id: "" }) } }}
        visible={addTaskDrawerVisible}
        height="auto"
      >
        <div style={{ marginBottom: '16px' }}>
          <label style={{ display: 'block', marginBottom: '8px' }}>
            Task Title <span style={{ color: 'red' }}>*</span>
          </label>
          <Input
            placeholder="Task Title"
            value={addTaskForm.title}
            onChange={(e) => setAddTaskForm({ ...addTaskForm, title: e.target.value })}
          />
        </div>

        <div style={{ marginBottom: '16px' }}>
          <label style={{ display: 'block', marginBottom: '8px' }}>
            Comments <span style={{ color: 'red' }}>*</span>
          </label>
          <Input.TextArea
            placeholder="Task Comments"
            value={addTaskForm.comments}
            onChange={(e) => setAddTaskForm({ ...addTaskForm, comments: e.target.value })}
            rows={3}  // You can adjust the number of rows to set the initial height of the TextArea
          />
        </div>

        <div style={{ marginBottom: '16px' }}>
          <label style={{ display: 'block', marginBottom: '8px' }}>
            Member <span style={{ color: 'red' }}>*</span>
          </label>
          <Select
            mode="multiple"
            placeholder="Select Assigned Members"
            value={addTaskForm.member}
            onChange={(e) => setAddTaskForm({ ...addTaskForm, member: e })}
            style={{ width: '100%' }}
            showSearch={false}
          >
            {employees.map((employee, index) => (
              <Option key={index} value={employee.uid}>
                {employee.name}
              </Option>
            ))}
          </Select>
        </div>

        <Button
          type="primary"
          onClick={handleAddNewTask}
          style={{ width: '100%' }}
          loading={submitLoading}  // Apply the loading state
          icon={submitLoading ? <Spin size="small" /> : null}
        >
          {isEditing ? 'Update Task' : 'Add Task'}
        </Button>
      </Drawer>

      {/* Drawer for selecting date range */}
      <Drawer
        title="Select Date Range"
        width={400}
        placement="right"
        closable={true}
        onClose={() => setIsDrawerVisible(false)}
        visible={isDrawerVisible}
      >
        <Calendar
          selectionMode="range" // Enable range selection
          onChange={handleCalendarChange}
        />
        <Button type="primary" onClick={handleConfirmDateRange} style={{ marginTop: 10 }}>
          Confirm
        </Button>
      </Drawer>
    </div>
  );
};

export default TaskList;
